.card {
    width: 200px;
    background: rgb(39, 39, 39);
    border: solid 3px rgb(0, 0, 0);
    border-radius: 15px;
    transition-timing-function:  ease-in-out;
    transition: 0.5s;
}

.card:hover {
    background: rgb(45, 45, 45);
    border: solid 3px rgb(84, 84, 84);
}

.border-top {
    border-top: solid 1px rgb(84, 84, 84);
}