.btnFileUpload {
    background-color: rgb(84, 84, 84);
    color: rgb(185, 185, 185);
    font-family: "Garamond-Medium";
    font-size: 1rem;
    padding: 5px 20px;
    border-radius: 10px;
    cursor: pointer;
    transition-timing-function:  ease-in-out;
    transition: 0.5s;
}

.btnFileUpload:hover {
    background-color: rgb(185, 185, 185);
    color: rgb(0, 0, 0);
}