html, body, #root {  
  /*background-color: rgb(55, 0, 0);*/
  background-color: rgb(39, 39, 39);
  /*font-family: "Geist";*/
  font-family: "Garamond-Regular";  
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255,  0.6);
}

p {
  font-size: 1rem;
  line-height: 150%;
}