.btnText {
    background-color: rgb(84, 84, 84);
    color: rgb(255, 255, 255);
    font-family: "Garamond-Medium";
    font-size: 1rem;
    padding: 5px 20px;    
    border: solid 2px rgba(255, 255, 255, 0.0);   
    border-radius: 10px; 
    cursor: pointer;
    transition-timing-function:  ease-in-out;
    transition: 0.2s;
}

.btnText:hover {
    background-color: rgb(185, 185, 185); 
    color: rgb(0, 0, 0);
    border: solid 2px rgb(185, 185, 185);
}