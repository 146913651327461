input {
    font-family: "Garamond-SemiBold";
    font-size: 1rem;
    outline: none;
    padding-left: 0px;
    padding-top: 5px;
    padding-right: 0px;
    padding-bottom: 5px;
}

.darkInput input {
    background: rgba(0, 0, 0, 0.0);
    color: rgb(185, 185, 185);
    border-top: solid 0px rgba(255, 255, 255, 0.0);
    border-left: solid 0px rgba(255, 255, 255, 0.0);
    border-right: solid 0px rgba(255, 255, 255, 0.0);
    border-bottom: solid 1px rgb(84, 84, 84);
    transition-timing-function:  ease-in-out;
    transition: 0.5s;
}

.darkInput input:hover {
    border-bottom: solid 1px rgb(185, 185, 185);
}

.darkInput input:focus {
    background: rgba(0, 0, 0, 0.3);
    border-bottom: solid 1px rgb(185, 185, 185);
}

textarea {
    font-family: "Geist";
    font-size: 1rem;
    outline: none;
    width: inherit;
    padding-left: 0px;
    padding-top: 5px;
    padding-right: 0px;
    padding-bottom: 5px;
    resize: none;
    overflow-y: hidden;
}

.darkTextArea textarea {
    background-color: rgba(0, 0, 0, 0.0);
    color: rgb(255, 255, 255);
    border-top: solid 0px rgba(255, 255, 255, 0.0);
    border-left: solid 0px rgba(255, 255, 255, 0.0);
    border-right: solid 0px rgba(255, 255, 255, 0.0);
    border-bottom: solid 1px rgb(255, 255, 255);
}

.darkTextArea textarea:focus {
    background-color: rgba(0, 0, 0, 0.4);
}

.checkbox-container {
    position: relative;
    padding-left: 25px;
    padding-top: 2px;
    cursor: pointer;
    user-select: none;
}

.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: rgba(0, 0, 0, 0.4);
    border: solid 1px rgb(255, 255, 255);
}

.checkbox-container:hover input ~ .checkmark {
    background-color: rgba(255, 255, 255, 0.3);
}

.checkbox-container input:checked ~ .checkmark {
    background-color: rgba(255, 255, 255, 1.0);
}

.checkmark::after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox-container input:checked ~ .checkmark::after {
    display: block;
}

.checkbox-container .checkmark::after {
    left: 5px;
    top: 1px;
    height: 8px;
    width: 3px;
    border: solid rgb(0, 0, 0);
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    padding: 0px;
    margin: 0px;
    height: 3px;
    border-radius: 2px;
    background: rgb(0, 0, 0);
    cursor: pointer;
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 12px;
    width: 12px;
    outline: none;
    border-radius: 50%;
    background: rgb(185, 185, 185);
    cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 12px;
    width: 12px;
    outline: none;
    border-radius: 50%;
    background: rgb(185, 185, 185);
    cursor: pointer;
}