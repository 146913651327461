.app-body {
    width: 40%;
}

@media screen and (max-width: 955px) {
    .app-body {
        width: 90%;
    }
}

.background-light {
    background-color: rgb(185, 185, 185);
}

.background-dark {
    background-color: rgb(39, 39, 39);
}

.background-verydark {
    background-color: rgb(20, 20, 20);
}

.flex {
    display: flex;
}

.wrap {
    flex-wrap: wrap;
}

.relative {
    position: relative;
}

.fixed {
    position: fixed;
}

.float {
    z-index: 1;
}

.absolute {
    position: absolute;
}

.position-bottom {
    bottom: 0;
}

.vertical-center {
    align-items: center;
}

.horizontal-center {
    justify-content: center;
}

.fullcenter {
    justify-content: center;
    align-items: center;
}

.vertical {
    flex-direction: column;
}

.column-justify-right {
    align-items: flex-end;
}

.gap-auto {
    justify-content: space-between;
}

.gap2 {
    gap: 2px;
}

.gap5 {
    gap:  5px;
}

.gap10 {
    gap: 10px;
}

.gap20 {
    gap: 20px;
}

.gap30 {
    gap: 30px;
}

.margin5 {
    margin: 5px;
}

.margin20 {
    margin: 20px;
}

.top-margin5 {
    margin-top: 5px;
}

.bottom-margin5 {
    margin-bottom: 5px;
}

.top-margin20 {
    margin-top: 20px;
}

.bottom-margin20 {
    margin-bottom: 20px;
}

.left-margin10 {
    margin-left: 10px;
}

.right-margin10 {
    margin-right: 10px;
}

.noSide-margin5 {
    margin-left: 0px;
    margin-top: 5px;
    margin-right: 0px;
    margin-bottom: 5px;
}

.pad5 {
    padding: 5px;
}

.pad10 {
    padding: 10px;
}

.top-pad2 {
    padding-top: 2px;
}

.top-pad5 {
    padding-top: 5px;
}

.top-pad10 {
    padding-top: 10px;
}

.top-pad50 {
    padding-top: 50px;
}

.bottom-pad5 {
    padding-bottom: 5px;
}

.bottom-pad8 {
    padding-bottom: 8px;
}

.bottom-pad10 {
    padding-bottom: 10px;
}

.left-pad2 {
    padding-left: 2px;
}

.left-pad10 {
    padding-left: 10px;
}

.right-pad10 {
    padding-right: 10px;
}

.noSide-pad10 {
    padding-left: 0px;
    padding-top: 10px;
    padding-right: 0px;
    padding-bottom: 10px;
}

.top-roundcorners8 {
    border-radius: 8px 8px 0px 0px;
}

.roundcorners10 {
    border-radius: 10px;
}

.mobile-height {
    max-height: 100%;
}

@media screen and (max-width: 600px) {
    .mobile-height {
        max-height: calc(100% - 50px);
    }
}

.tweetinput {
    display: flex;
}

.tweetinput-mobile {
    display: none;
}

@media screen and (max-width: 600px) {
    .tweetinput {
        display: none;
    }
    
    .tweetinput-mobile {
        display: flex;
    }
}

.overflow-y-scroll {
    overflow-y: scroll;
}

.ovreflow-y-auto {
    overflow-y: auto;
}

.overflow-y-hidden {
    overflow-y: hidden;
}

.image-dynamic {
    height: auto;
    max-width: 100%;
}