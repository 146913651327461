@font-face {
    font-family: Garamond-ExtraBold;
    src: url(../fonts/EBGaramond-ExtraBold.ttf);
}

@font-face {
    font-family: Garamond-Bold;
    src: url(../fonts/EBGaramond-Bold.ttf);
}

@font-face {
    font-family: Garamond-SemiBold;
    src: url(../fonts/EBGaramond-SemiBold.ttf);
}

@font-face {
    font-family: Garamond-Medium;
    src: url(../fonts/EBGaramond-Medium.ttf);
}

@font-face {
    font-family: Garamond-Regular;
    src: url(../fonts/EBGaramond-Regular.ttf);
}

@font-face {
    font-family: Garamond-ExtraBoldItalic;
    src: url(../fonts/EBGaramond-ExtraBoldItalic.ttf);
}

@font-face {
    font-family: Garamond-BoldItalic;
    src: url(../fonts/EBGaramond-BoldItalic.ttf);
}

@font-face {
    font-family: Garamond-SemiBoldItalic;
    src: url(../fonts/EBGaramond-SemiBoldItalic.ttf);
}

@font-face {
    font-family: Garamond-MediumItalic;
    src: url(../fonts/EBGaramond-MediumItalic.ttf);
}

@font-face {
    font-family: Garamond-Italic;
    src: url(../fonts/EBGaramond-Italic.ttf);
}

.ExtraBold {
    font-family: "Garamond-ExtraBold";
}

.ExtraBoldItalic {
    font-family: "Garamond-ExtraBoldItalic";
}

.Bold {
    font-family: "Garamond-Bold";
}

.BoldItalic {
    font-family: "Garamond-BoldItalic";
}

.SemiBold {
    font-family: "Garamond-SemiBold";
}

.SemiBoldItalic {
    font-family: "Garamond-SemiBoldItalic";
}

.Medium {
    font-family: "Garamond-Medium";
}

.MediumItalic {
    font-family: "Garamond-MediumItalic";
}

.Regular {
    font-family: "Garamond-Regular";
}

.Italic {
    font-family: "Garamond-Italic";
}

.font-white {
    color: rgb(185, 185, 185);
}

.font-yellow {
    color: rgb(255, 255, 0);
}

.underline {
    text-decoration: none;
    cursor: pointer;
  }
  
  .underline:hover {
    text-decoration: underline;
  }