.dropdownBorder {
    background-color: rgb(39, 39, 39);
    border: solid 2px rgb(84, 84, 84);
    border-radius: 8px;
    transition-timing-function:  ease-in-out;
    transition: 0.5s;
}

.dropdownBorder:hover {
    border: solid 2px rgb(185, 185, 185);
}

.options {
    font-family: "Garamond-SemiBold";
    font-size: 1.4rem;
    color: rgb(185, 185, 185);
    background-color: rgba(185, 185, 185, 0.0);
    transition-timing-function:  ease-in-out;
    transition: 0.5s;
}

.options:hover {
    color: rgb(39, 39, 39);
    background-color: rgba(185, 185, 185, 1.0);
}