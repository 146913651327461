.pointer {
    cursor: pointer;
}

.background {
    background: rgba(255, 255, 255, 0.0);    
    transition-timing-function: ease-in-out;
    transition: 0.2s;
}

.background:hover {
    background: rgba(255, 255, 255, 0.0); 
}

.backgroundActive {
    background: rgba(255, 255, 255, 0.0); 
}