::-webkit-scrollbar {
    width: 8px;
    height: 8px;  
}

::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255,  0.2);    
}

::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255,  0.4)    
}

::-webkit-scrollbar-track {
    background: rgba(255, 255, 255,  0.0)  
}

::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0.0);
}

::-webkit-scrollbar-button {
    background: rgb(39, 39, 39);
}

.scroller {
    scrollbar-width: thin;
    scrollbar-color: rgba(255, 255, 255,  0.4) rgba(255, 255, 255,  0.0);
}